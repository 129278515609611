import React from 'react'
// import { useWeb3React } from '@web3-react/core'
import Locker from '.'

const Account: React.FC = () => {
  // const { account } = useWeb3React()

  return <Locker />
}

export default Account
